/*=======================
      NAVBAR
=======================*/
#span {
    display: inline;
}

.navbar {
    background-color: #121212;
    padding-right: 4rem;
    height: 3rem;
    align-content: center;
    justify-content: center;
}

.carticon {
    filter: invert(1);
}

.lista {
        display: flex;
        margin: 0 auto;
        flex-wrap: wrap;
        list-style: none;
        justify-content: flex-end;
    }

.link {
        display: flex;
        text-decoration: none;
        color: #F3E8EE;
        font-size: 1.5rem;
        margin-right: 3rem;
        margin-top: .3rem;
    }

.logo {
    max-width: 10%;
    min-width: 8rem;
    padding: 0 .8rem;
}

