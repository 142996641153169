html, body {
  margin: 0px;
  height: 100%;
  text-align: center;
  background-color: rgba(245, 243, 243, 0.877);
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#saludo {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: rgb(28, 24, 94);
  font-weight: bold;
}

.contenedor {
  height: auto;
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  justify-items: center;
  }

.prodImg {
  margin: 3rem auto;
  width: 80%;
}

.detalle {
  font-size: 1.5rem;
}

.cartitem {
  display: flex;
  flex-direction: column;
  width: 2fr;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.cartimg {
  width: 20rem;
  padding: 1rem;
}

.card-header {
font-size: 1.5rem;
background-color:rgb(28, 24, 94);
color: white;
}

.card-price {
  font-size: 1.5rem;
  background-color: rgb(28, 24, 94);
  color: white;  
  }

.card-body {
  background-color: rgb(224, 217, 212);
}

.card-footer {
  background-color: rgb(240, 231, 223);
}

.checkout {
  grid-column: 2;
  margin: 2rem auto;
  height: 100%;
  font-size: 1.5rem;
}

footer {
  display: flex;
  justify-content: center;
  border: solid 3px black;
  background-color: black;
  color: white;
  padding-top: 1rem;
  padding-left: 3rem; }