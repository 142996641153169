body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*=======================
      NAVBAR
=======================*/
#span {
    display: inline;
}

.navbar {
    background-color: #121212;
    padding-right: 4rem;
    height: 3rem;
    align-content: center;
    justify-content: center;
}

.carticon {
    -webkit-filter: invert(1);
            filter: invert(1);
}

.lista {
        display: flex;
        margin: 0 auto;
        flex-wrap: wrap;
        list-style: none;
        justify-content: flex-end;
    }

.link {
        display: flex;
        text-decoration: none;
        color: #F3E8EE;
        font-size: 1.5rem;
        margin-right: 3rem;
        margin-top: .3rem;
    }

.logo {
    max-width: 10%;
    min-width: 8rem;
    padding: 0 .8rem;
}


html, body {
  margin: 0px;
  height: 100%;
  text-align: center;
  background-color: rgba(245, 243, 243, 0.877);
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#saludo {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: rgb(28, 24, 94);
  font-weight: bold;
}

.contenedor {
  height: auto;
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  justify-items: center;
  }

.prodImg {
  margin: 3rem auto;
  width: 80%;
}

.detalle {
  font-size: 1.5rem;
}

.cartitem {
  display: flex;
  flex-direction: column;
  width: 2fr;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.cartimg {
  width: 20rem;
  padding: 1rem;
}

.card-header {
font-size: 1.5rem;
background-color:rgb(28, 24, 94);
color: white;
}

.card-price {
  font-size: 1.5rem;
  background-color: rgb(28, 24, 94);
  color: white;  
  }

.card-body {
  background-color: rgb(224, 217, 212);
}

.card-footer {
  background-color: rgb(240, 231, 223);
}

.checkout {
  grid-column: 2;
  margin: 2rem auto;
  height: 100%;
  font-size: 1.5rem;
}

footer {
  display: flex;
  justify-content: center;
  border: solid 3px black;
  background-color: black;
  color: white;
  padding-top: 1rem;
  padding-left: 3rem; }
